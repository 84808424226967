import {axios, cancelTokens, getAuthToken} from '.';
import ax from "axios";

const getListofSubscription = async (tokenFromServer) => {
    const url = `api/subscription/`;
    let token = getAuthToken();
    if (tokenFromServer)
        token = {headers: {Authorization: `Token ${tokenFromServer}`}};
    return await axios.get(url, {...token});
};


const confirmYearlyPayment = async (payload) => {
    const url = `/api/upgrade-subscription/`
    const token = getAuthToken()
    return await axios.post(url, payload, {...token})
}

const confirmPayment = async (payload) => {
    const url = `api/subscription/`;
    const token = getAuthToken();
    return await axios.post(url, payload, {...token});

};

const confirmPurchase = async (payload) => {
    const url = `api/purchase/`;
    const token = getAuthToken();
    return await axios.post(url, payload, {...token});
}

const cancelSubscription = async (payload) => {
    const url = `api/cancel-subscription/`;
    const token = getAuthToken();
    return await axios.post(url, payload, {...token});
};

const renewSubscription = async (payload) => {
    const url = `api/renew-subscription/`;
    const token = getAuthToken();
    return await axios.post(url, payload, {...token});
};

const subscriberList = async (idOrUrl, isAuthRequired, param) => {
    cancelTokens?.map((source)=> {
        source.cancel({isCancel: true})
    });
    cancelTokens.length = 0;
    const cancelToken = ax.CancelToken.source();
    cancelTokens.push(cancelToken);
    let url = `api/podcast-subscriber/${idOrUrl}/?${param}`;
    const token = isAuthRequired ? getAuthToken() : {};
    return await axios.get(url, {...token, cancelToken: cancelToken.token});
};
const customersList = async (idOrUrl, isAuthRequired, param) => {
    cancelTokens?.map((source)=> {
        source.cancel({isCancel: true})
    });
    cancelTokens.length = 0;
    const cancelToken = ax.CancelToken.source();
    cancelTokens.push(cancelToken);
    let url = `api/audio-series-customers/${idOrUrl}/?${param}`;
    const token = isAuthRequired ? getAuthToken() : {};
    return await axios.get(url, {...token, cancelToken: cancelToken.token});
};

const donorsSubscriberList = async (idOrUrl, isAuthRequired, param) => {
    cancelTokens?.map((source)=> {
        source.cancel({isCancel: true})
    });
    cancelTokens.length = 0;
    const cancelToken = ax.CancelToken.source();
    cancelTokens.push(cancelToken);
    let url = `api/donation-subscriber/${idOrUrl}/?${param}`;
    const token = isAuthRequired ? getAuthToken() : {};
    return await axios.get(url, {...token, cancelToken: cancelToken.token});
};

const donorsCustomerList = async (idOrUrl, isAuthRequired, param) => {
    cancelTokens?.map((source)=> {
        source.cancel({isCancel: true})
    });
    cancelTokens.length = 0;
    const cancelToken = ax.CancelToken.source();
    cancelTokens.push(cancelToken);
    let url = `api/donation-customers/${idOrUrl}/?${param}`;
    const token = isAuthRequired ? getAuthToken() : {};
    return await axios.get(url, {...token, cancelToken: cancelToken.token});
};

const downloadSubscribersCSV = async (slug) => {
    const url = `api/csv-download-podcast-subscriber/${slug}/`;
    const token = getAuthToken();
    return await axios.get(url, {...token, responseType: 'arraybuffer'});
};

const downloadDonationSubscribersCSV = async (slug) => {
    const url = `api/csv-download-donation-subscriber/${slug}/`;
    const token = getAuthToken();
    return await axios.get(url, {...token, responseType: 'arraybuffer'});
};

export {
    confirmPayment,
    getListofSubscription,
    cancelSubscription,
    renewSubscription,
    subscriberList,
    customersList,
    donorsSubscriberList,
    donorsCustomerList,
    downloadSubscribersCSV,
    confirmPurchase,
    confirmYearlyPayment,
    downloadDonationSubscribersCSV
};
