import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { supportedLanguages, translate as t } from 'utils/helpers';
import PropTypes from 'prop-types';
import routes from 'utils/routes';
import LanguageChanger from 'components/LanguageChanger';
import styles from './Footer.module.scss';

const PublicFooter = ({ lang, langChanger }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const year = new Date().getFullYear();

  return (
    <Container className={`${styles.mainFooterWrap} wider`}>
      <div className={styles.mainFooter}>
        <Row>
          <Col lg="3" md="3" xs="12" className={`${styles.singleCol}`}>
            <a href={routes.home(lang)} className="navbar-brand">
              <img
                src="/mumbler-logo.svg"
                className={styles.logo}
                alt="Mumbler logo"
              />
            </a>
            {langChanger && <LanguageChanger />}
            <p className={styles.copyrightText}>
              {t(lang, 'footer')('copyright', { year })}
            </p>
          </Col>
          <Col
            lg="3"
            md="3"
            xs="12"
            className={`${styles.singleCol} ${styles.spaceCol}`}>
            <ul className="list-unstyled">
              <li>
                <p>
                  <a href={routes.features(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('features')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.pricing(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('pricing')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.blog(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('blog')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.resources(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('resources')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.podcastHosting(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('podcastHosting')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.affiliateProgram(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('affiliateProgram')}
                  </a>
                </p>
              </li>
            </ul>
          </Col>
          <Col
            lg="3"
            md="3"
            xs="12"
            className={`${styles.singleCol} ${styles.spaceCol}`}>
            <ul className="list-unstyled">
              <li>
                <p>
                  <a href={routes.discover(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('discover')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.team(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('team')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.creatorStories(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('creatorStories')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.privacyPolicy(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('legal')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.termsAndConditions(lang)} className={styles.footerLink}>
                    {t(lang, 'footer')('termsAndConditions')}
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href={routes.help} target="_blank" className={styles.footerLink}>
                    {t(lang, 'footer')('help')}
                  </a>
                </p>
              </li>
            </ul>
          </Col>
          <Col
            lg="3"
            md="3"
            xs="12"
            className={`${styles.socialMedia} ${styles.singleCol} ${styles.spaceCol}`}>
            <div className={styles.socialIconsWrap}>
              <a
                className={`${styles.singleIconWrap} no-effect`}
                href="https://twitter.com/getmumbler"
                rel="noreferrer"
                target="_blank">
                <img
                  src="/social-icons/twitter-icon.svg"
                  className={styles.singleIcon}
                  alt=""
                />
              </a>
              <a
                className={`${styles.singleIconWrap} no-effect`}
                href="https://www.facebook.com/getmumbler"
                rel="noreferrer"
                target="_blank">
                <img
                  src="/social-icons/facebook-icon.svg"
                  className={styles.singleIcon}
                  alt=""
                />
              </a>
              <a
                className={`${styles.singleIconWrap} no-effect`}
                href="https://www.instagram.com/getmumbler/"
                rel="noreferrer"
                target="_blank">
                <img
                  src="/social-icons/instagram-icon.svg"
                  className={styles.singleIcon}
                  alt=""
                />
              </a>
              <a
                className={`${styles.singleIconWrap} no-effect`}
                href="https://www.youtube.com/@getmumbler"
                rel="noreferrer"
                target="_blank">
                <img
                  src="/social-icons/youtube-icon.svg"
                  className={`${styles.singleIcon}`}
                  alt=""
                />
              </a>
              <a
                className={`${styles.singleIconWrap} no-effect`}
                href="https://t.me/+H-8FxfOwYYBkOTA0"
                rel="noreferrer"
                target="_blank">
                <img
                  src="/social-icons/telegram-icon.svg"
                  className={`${styles.singleIcon}`}
                  alt=""
                />
              </a>
              {/*{lang === 'es' && <a*/}
              {/*  className={`${styles.singleIconWrap} no-effect`}*/}
              {/*  href="https://comunidad.mumbler.io/"*/}
              {/*  rel="noreferrer"*/}
              {/*  target="_blank">*/}
              {/*  <img*/}
              {/*    src="/social-icons/discord-icon.svg"*/}
              {/*    className={`${styles.singleIcon} ${styles.discordIcon}`}*/}
              {/*    alt=""*/}
              {/*  />*/}
              {/*</a>}*/}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

PublicFooter.defaultProps = {
  langChanger: true
};

PublicFooter.propTypes = {
  langChanger: PropTypes.bool,
  lang: PropTypes.oneOf(supportedLanguages)
};

export default PublicFooter;