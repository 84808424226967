import React from 'react';
import { NavDropdown, Form } from 'react-bootstrap';
import styles from 'components/Footer/Footer.module.scss';
import useLang from 'context/LanguageContext';
import Router from 'next/router';
import { supportedLanguages } from 'utils/helpers';

const LanguageChanger = () => {
  const { lang, setLang } = useLang();
  const handleLanguageChange = ({ target: { value: language } }) => {
    const urlArray = window?.location?.pathname?.substring(1)?.split('/');
    const currentLanguage = urlArray?.[0];
    if (supportedLanguages?.includes(currentLanguage)) {
      urlArray.shift();
      const newURL = [language, ...urlArray].join('/') + window?.location?.search;
      Router.push(`/${newURL}`);
    } else {
      setLang(language);
    }
  };
  return (
    <>
      <Form.Control
        style={{ maxWidth: 200 }}
        as="select"
        className="my-2"
        onChange={handleLanguageChange}
        value={lang}>
        <option value="en">English</option>
        <option value="es">Español</option>
      </Form.Control>
      {/* <NavDropdown */}
      {/*  title="Language" */}
      {/*  id="nav-dropdown" */}
      {/*  // renderMenuOnMount */}
      {/*  className={`user-nav-dropdown ${styles.langDropdown}`}> */}
      {/*  <NavDropdown.Item */}
      {/*    className={`no-effect ${styles.langItem} ${ */}
      {/*      lang === 'en' ? styles.active : '' */}
      {/*    }`} */}
      {/*    href="#" */}
      {/*    onClick={() => handleLanguageChange('en')}> */}
      {/*    English */}
      {/*  </NavDropdown.Item> */}
      {/*  <NavDropdown.Item */}
      {/*    className={`no-effect ${styles.langItem} ${ */}
      {/*      lang === 'es' ? styles.active : '' */}
      {/*    }`} */}
      {/*    href="#" */}
      {/*    onClick={() => handleLanguageChange('es')}> */}
      {/*    Español */}
      {/*  </NavDropdown.Item> */}
      {/* </NavDropdown> */}
    </>
  );
};

export default LanguageChanger;
