import { axios, getAuthToken, cancelTokens } from '.';
import ax from 'axios';

const donationPurchase = async (payload) => {
    let url = `api/donation-purchase/`;
    const token = getAuthToken();
    return await axios.post(url, payload, { ...token });
};

const donationSubscription = async (payload) => {
    let url = `api/donation-subscription/`;
    const token = getAuthToken();
    return await axios.post(url, payload, { ...token });
};

const donationAnalytics = async (id, tokenFromServer) => {
    const url = `/api/donation-analytics/${id}/`;
    let token = getAuthToken();
    if (tokenFromServer) {
        token = { headers: { Authorization: `Token ${tokenFromServer}` } };
    }
    const response = await axios.get(url, { ...token });
    return response;
};

const donationDetail = async (id, isAuthRequired, tokenFromServer, params) => {
    const url = `api/donation/${id}/`;
    const tokenHeaders = getAuthToken();
    let token = isAuthRequired ? tokenHeaders : {};
    if (tokenFromServer) {
        token = { headers: { Authorization: `Token ${tokenFromServer}` } };
    }
    const response = await axios.get(url, { ...token, params });
    return response;
};

const cancelDonationSubscription = async (payload) => {
    let url = `api/cancel-donation-subscription/`;
    const token = getAuthToken();
    return await axios.post(url, payload, { ...token });
};

const donationPayoutAmount = async () => {
    const url = `api/donations-payout-amount/`;
    const tokenHeaders = getAuthToken();
    const response = await axios.get(url, { ...tokenHeaders });
    return response;
};

const payoutDonationDashboard = async () => {
    const url = `api/request-donation-payout/`;
    const tokenHeaders = getAuthToken();
    const response = await axios.get(url, { ...tokenHeaders });
    return response;
};

export {
    donationPurchase,
    donationSubscription,
    donationAnalytics,
    donationDetail,
    cancelDonationSubscription,
    donationPayoutAmount,
    payoutDonationDashboard
};